<template>
  <div>
    <Student v-if="isStudent" />
    <Teacher v-else />
  </div>
</template>

<script>
import Student from "./components/student.vue"
import Teacher from "./components/teacher.vue"
  export default {
    components:{
      Student,
      Teacher
    },
    computed:{
      isStudent(){
        return this.$store.state.userInfo.roleCode=='student'
      },
    },
  }
</script>

<style lang="less" scoped>

</style>