<template>
  <div class="root">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title">团队预约</span>
        <p class="nav-box">
          <span
            @click="handleNav(nav)"
            :class="activeNav.title === nav.title ? 'active' : ''"
            v-for="(nav, i) in navArr"
            :key="i"
            >{{ nav.title }}</span
          >
        </p>
      </div>
      <div v-if="reserveBill.length > 0">
        <div v-for="(item, i) in reserveBill" :key="i" class="item">
          <p class="time">参观时间：{{ item.reserveDate }}</p>
          <el-row>
            <el-col :span="12">
              <div class="info">
                <el-image
                  style="width: 220px; height: 100px"
                  :src="item.sitePicture"
                  fit="cover"
                ></el-image>
                <div class="text">
                  <p>{{ item.siteName }}</p>
                  <p>{{ item.siteAddress }}</p>
                </div>
              </div>
            </el-col>
            <el-col :span="4">{{
              item.reserveStatus == 1
                ? "审核中"
                : item.reserveStatus == 2
                ? "预约成功"
                : "预约被拒"
            }}</el-col>
            <el-col :span="4"
              ><span
                @click="infoIndex = infoIndex === i ? -1 : i"
                class="lookinfo"
                >预约详情</span
              ></el-col
            >
            <el-col :span="4">
              <span
                @click="removeReservecourse(item)"
                :class="item.reserveStatus == 2 ? 'cancel' : 'cancel disabled'"
                >取消预约</span
              >
            </el-col>
          </el-row>
          <div v-if="infoIndex === i" class="reserveInfo">
            <div class="reserveInfo-top">
              <div class="left-text">
                <p>场景：{{ item.siteName }}</p>
                <p>
                  预约状态：{{
                    item.reserveStatus == 1
                      ? "审核中"
                      : item.reserveStatus == 2
                      ? "预约成功"
                      : "预约被拒"
                  }}
                </p>
                <p>预约时间：{{ item.createTime }}</p>
                <p>参观时间：{{ item.reserveDate }}</p>
                <p>场景地址：{{ item.siteAddress }}</p>
                <p>领队姓名：{{ item.leaderName }}</p>
                <p>领队所属学校：{{ item.leaderSchool }}</p>
                <p>领队联系电话：{{ item.leaderPhone }}</p>
                <p>团队容纳人数：{{ item.groupNumber }}</p>
                <p>当前团队人数：{{ item.groupNumberNow }}</p>
              </div>
              <div class="right-qr">
                <el-image
                  style="width: 160px; height: 160px; padding: 5px"
                  :src="item.qrCode"
                ></el-image>
                <p v-if="item.qrCode">
                  <a :href="item.qrCode">下载通行二维码</a>
                </p>
              </div>
            </div>
            <div class="reserveInfo-bottom">
              <el-table
                :data="item.studentList"
                border
                stripe
                size="mini"
                style="width: 100%"
              >
                <el-table-column label="姓名" width="200">
                  <template slot-scope="scope">
                    <span>{{ scope.row.studentName }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="性别" width="200">
                  <template slot-scope="scope">
                    <span>{{ scope.row.studentSex == 1 ? "男" : "女" }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="年级" width="200">
                  <template slot-scope="scope">
                    <span>{{ studentGrade(scope.row.studentAge) }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="身份证号">
                  <template slot-scope="scope">
                    <span>{{ scope.row.studentIdentityNumber }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <Empty v-else>暂无该状态的数据</Empty>
      <div class="pages">
        <el-pagination
          v-if="total > size"
          @current-change="toggleCurrent"
          :current-page="current"
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="size"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getReservegroupList_api } from "@/api/user.js";
import { removeReservegroup_api } from "@/api/scene.js";
import { sessionGet } from "@/utils/local.js";
import Empty from "@/components/empty.vue";
export default {
  components: { Empty },
  data() {
    return {
      size: 5, // 每页数量
      current: 1, // 当前页
      reserveBill: [], // 预约数据
      infoIndex: -1, // 展示详情的预约索引
      total: 0, // 总数
      activeNav: { title: "全部", num: null }, // 当前激活的导航，默认全部
      navArr: [
        // 所有导航
        { title: "全部", num: null },
        { title: "审核中", num: 1 },
        { title: "预约成功", num: 2 },
        { title: "预约被拒", num: 3 },
      ],
      studentGradeArr: [
        { value: "1", label: "一年级" },
        { value: "2", label: "二年级" },
        { value: "3", label: "三年级" },
        { value: "4", label: "四年级" },
        { value: "5", label: "五年级" },
        { value: "6", label: "六年级" },
        { value: "7", label: "初一" },
        { value: "8", label: "初二" },
        { value: "9", label: "初三" },
      ],
    };
  },
  computed: {
    studentGrade() {
      return function (num) {
        let label;
        this.studentGradeArr.forEach((item) => {
          if (item.value == num) label = item.label;
        });
        return label;
      };
    },
  },
  methods: {
    // 获取预约数据
    async getReserveBill(reserveStatus = null) {
      let { code, data, msg } = await getReservegroupList_api({
        createUser: sessionGet("userId"),
        size: this.size,
        current: this.current,
        reserveStatus,
      });
      if (data.records.length === 0 && this.current > 1) {
        this.current--;
        this.getReserveBill(reserveStatus);
      } else {
        this.infoIndex = -1;
        this.reserveBill = data.records;
        this.total = data.total;
      }
    },
    // 切换预约状态导航
    handleNav(val) {
      this.current = 1;
      this.activeNav = { ...val };
      this.getReserveBill(val.num);
    },
    // 切换页码
    toggleCurrent(current) {
      this.current = current;
      this.getReserveBill(this.activeNav.num);
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    // 取消预约
    removeReservecourse(item) {
      if (item.reserveStatus != 2) return null;
      this.$confirm("此操作将永久取消该预约。是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let { code, msg } = await removeReservegroup_api({ ids: item.id });
          if (code === 200) {
            this.$message({
              type: "success",
              message: msg,
            });
            this.getReserveBill(this.activeNav.num);
          } else {
            this.$message.error(msg);
          }
        })
        .catch(() => {
          return false;
        });
    },
  },
  created() {
    this.getReserveBill();
  },
};
</script>

<style lang="less" scoped>
@title-color: #0079fe;
@btn-color: #f66866;
.box-card {
  margin-bottom: 30px;
  .clearfix {
    display: flex;
    .title {
      margin-right: 20px;
      font-weight: 700;
    }
    .nav-box {
      span {
        padding: 4px 10px;
        font-size: 14px;
        user-select: none;
        cursor: pointer;
        &.active {
          color: @title-color;
          border-bottom: 1px solid @title-color;
        }
      }
    }
  }
  .item {
    margin-bottom: 20px;
    .reserveInfo {
      .reserveInfo-top {
        display: flex;
        border-left: 1px solid #eee;
        border-right: 1px solid #eee;
        .left-text {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          align-content: space-around;
          padding: 10px;
          p {
            width: 50%;
            font-size: 14px;
            color: #777;
          }
        }
        .right-qr {
          p {
            text-align: center;
            padding-bottom: 5px;
            a {
              font-size: 14px;
              &:hover {
                color: @title-color;
              }
            }
          }
        }
      }
      .reserveInfo-bottm {
        .el-table th {
          background-color: #aaa;
        }
      }
    }
    .time {
      line-height: 40px;
      background-color: #f2f2f2;
      color: #888;
      text-indent: 1em;
      font-size: 14px;
    }
    /deep/.el-col {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      color: #777;
      font-size: 14px;
      line-height: 1.5em;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      &:first-child {
        justify-content: left;
      }
      .cancel {
        user-select: none;
        cursor: pointer;
        &:hover {
          color: @btn-color;
        }
        &.disabled {
          cursor: not-allowed;
          &:hover {
            color: #777;
          }
        }
      }
      .lookinfo {
        user-select: none;
        cursor: pointer;
        &:hover {
          color: @title-color;
        }
      }
      .info {
        display: flex;
        .text {
          padding: 10px;
          display: flex;
          flex-direction: column;
          line-height: 2em;
        }
      }
    }
  }
  .pages {
    display: flex;
    justify-content: center;
  }
}
</style>