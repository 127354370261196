<template>
  <div class="root">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title">我的预约</span>
        <p class="nav-box">
          <span
              @click="handleNav(nav)"
              :class="activeNav.title === nav.title ? 'active' : ''"
              v-for="(nav, i) in navArr"
              :key="i"
          >{{ nav.title }}</span
          >
        </p>
      </div>
      <div v-if="reserveBill.length > 0" class="pad-top">
        <div v-for="(item, i) in reserveBill" :key="i" class="item">
          <el-row class="bord-bot">
            <el-col :span="7" class="bord">
              <p class="time">下一课时开始时间：{{ item.courseTime }}</p>
            </el-col>
            <el-col :span="3" class="bord">
              <p >授课老师：{{item.teacherName}}</p>
            </el-col>
            <el-col :span="5" class="bord">
              <p >联系电话：{{item.phoneNumber}}</p>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <div class="info">
                <el-image
                    style="width: 220px; height: 100px"
                    :src="item.coursePicture"
                    fit="cover"
                ></el-image>
                <div class="text">
                  <p>{{ item.courseName }}</p>
                  <p>{{ item.courseSite }}</p>
                </div>
              </div>
            </el-col>
            <el-col
                :span="item.reserveStatusDes && (item.reserveStatus == 1 || item.reserveStatus == 5) ? 3 : 7"
            >预约人：<br />{{ item.studentName }}</el-col
            >
            <el-col
                v-if="item.reserveStatusDes"
                :span="(item.reserveStatus == 1 || item.reserveStatus == 5) ? 3 : 6"
            >{{ item.reserveStatusDes }}</el-col
            >
            <el-col
                v-if="!item.reserveStatus"
                :span="3"
            >候补中</el-col
            >
            <el-col
                v-if="item.qrCode && (item.reserveStatus == 1 || item.reserveStatus == 5)"
                :span="item.reserveStatus == 1 ? 4 : 7"
            ><a :href="item.qrCode">下载通行二维码</a></el-col
            >
            <el-col
                v-if="!item.reserveStatus || item.reserveStatus == 1"
                :span="3"
            >
              <span
                  v-if="item.reserveStatus == 1"
                  @click="removeReservecourse(item.id)"
                  class="cancel"
              >取消预约</span
              >
              <span
                  v-else-if="!item.reserveStatus"
                  @click="removeAlternateBill(item.id)"
                  class="cancel"
              >取消候补</span
              >
            </el-col>
          </el-row>
        </div>
      </div>
      <Empty v-else>暂无该状态的数据</Empty>
      <div class="pages">
        <el-pagination
            v-if="total > size"
            @current-change="toggleCurrent"
            :current-page="current"
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="size"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  getReserveBill_api,
  getAlternateBill_api,
  removeReservecourse_api,
  removeAlternateBill_api,
} from "@/api/user.js";
import { sessionGet } from "@/utils/local.js";
import Empty from "@/components/empty.vue";
export default {
  components: { Empty },
  data() {
    return {
      size: 5, // 每页数量
      current: 1, // 当前页
      reserveBill: [], // 预约数据
      total: 0, // 总数
      activeNav: { title: "", num: 1 }, // 当前激活的导航，默认已成功
      // activeNav: { title: "全部", num: null }, // 当前激活的导航，默认全部
      navArr: [
        // 所有导航
        // { title: "全部", num: null },
        { title: "已成功", num: 1 },
        // { title: "已失败", num: 3 },
        { title: "", num: 5 },
        { title: "已取消", num: 4 },
        { title: "候补中", num: "候补中" },
      ],
    };
  },
  methods: {
    // 获取预约数据
    async getReserveBill(reserveStatus = 1) {
      let { data } = await getReserveBill_api({
        // studentId: "1375279068392124418",
        studentId: sessionGet("userId"),
        size: this.size,
        current: this.current,
        reserveStatus,
      });
      if (data.records.length === 0 && this.current > 1) {
        this.current--;
        this.getReserveBill(reserveStatus);
      } else {
        this.reserveBill = data.records;
        this.total = data.total;
      }
    },
    // 获取候补记录
    async getAlternateBill() {
      let { code, data } = await getAlternateBill_api({
        // studentId: "1375279068392124418",
        studentId: sessionGet("userId"),
        size: this.size,
        current: this.current,
      });
      if (code === 200) {
        this.reserveBill = data.records;
        this.total = data.total;
      }
    },
    // 切换预约状态导航
    handleNav(val) {
      this.current = 1;
      this.activeNav = { ...val };
      if (val.num === "候补中") {
        // 发送候补请求
        this.getAlternateBill();
      } else {
        this.getReserveBill(val.num);
      }
    },
    // 切换页码
    toggleCurrent(current) {
      this.current = current;
      this.getReserveBill(this.activeNav.num);
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    // 取消候补
    removeAlternateBill(ids) {
      this.$confirm("此操作将取消该候补，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let { code, msg } = await removeAlternateBill_api({ ids });
        if (code === 200) {
          this.$message({
            type: "success",
            message: msg,
          });
          this.getAlternateBill();
        } else {
          this.$message.error(msg);
        }
      });
    },
    // 取消预约
    removeReservecourse(ids) {
      this.$confirm(
          "此操作将退选该课程，每个账号每个月只能退选三次，超过三次将限制选课。是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(async () => {
        let { code, msg } = await removeReservecourse_api({ ids });
        if (code === 200) {
          this.$message({
            type: "success",
            message: msg,
          });
          this.getReserveBill(this.activeNav.num);
        } else {
          this.$message.error(msg);
        }
      });
    },
  },
  created() {
    this.getReserveBill();
  },
};
</script>

<style lang="less" scoped>
@title-color: #0079fe;
@btn-color: #f66866;
.box-card {
  margin-bottom: 30px;
  .clearfix {
    display: flex;
    .title {
      margin-right: 20px;
      font-weight: 700;
    }
    .nav-box {
      span {
        padding: 4px 10px;
        font-size: 14px;
        user-select: none;
        cursor: pointer;
        &.active {
          color: @title-color;
          border-bottom: 1px solid @title-color;
        }
      }
    }
  }
  .item {
    margin-bottom: 20px;
    .time {
      line-height: 40px;
      background-color: #f2f2f2;
      color: #888;
      text-indent: 1em;
      font-size: 14px;
    }
    /deep/.el-col {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      color: #777;
      font-size: 14px;
      line-height: 1.5em;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      a:hover{
        color:@title-color;
      }
      &:first-child {
        justify-content: left;
      }
      .cancel {
        user-select: none;
        cursor: pointer;
        &:hover {
          color: @btn-color;
        }
      }
      .info {
        display: flex;
        .text {
          padding: 10px;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
  .pages {
    display: flex;
    justify-content: center;
  }
}
.bord{
  border-right:0 !important;
  border-bottom: 0 !important;
  height: 40px !important;
}
.bord-bot{
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
}
.pad-top {
  margin-top: -12px !important;
}
</style>
